import * as React from "react";

// markup
//
const AccentedText = ({ children }) => {
  return <span className="text-red-600">{children}</span>;
};
const IndexPage = () => {
  return (
    <main className="container mx-auto py-20 px-5 text-xl">
      <title>Manuel Kallenbach</title>
      <p>
        My name is <AccentedText>Manuel Kallenbach</AccentedText>. I am a{" "}
        <AccentedText>start-up CTO</AccentedText>. Before, I worked as a{" "}
        <AccentedText>web developer</AccentedText> for almost 10 years. I
        believe in <AccentedText>empathy</AccentedText>,{" "}
        <AccentedText>simplicity</AccentedText>,{" "}
        <AccentedText>pragmatism</AccentedText>, and{" "}
        <AccentedText>getting things done</AccentedText>.
      </p>

      <p className="py-16">
        You can find out more about me on{" "}
        <a className="underline" href="https://home.social/@manukall">Mastodon</a>,{" "}
        <a className="underline" href="https://twitter.com/manukall">
          Twitter
        </a>
        ,{" "}
        <a
          className="underline"
          href="https://www.linkedin.com/in/manuel-kallenbach-29746215"
        >
          LinkedIn
        </a>
        , or{" "}
        <a className="underline" href="https://github.com/manukall">
          GitHub
        </a>
        .
      </p>

      <p>
        My email address is{" "}
        <a href="mailto:mk@manukall.de" className="underline">
          mk@manukall.de
        </a>
        .
      </p>
    </main>
  );
};

export default IndexPage;

